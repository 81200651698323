<template>
  <div v-if="$store.state.fastPermissoes.ativo == 'S' && ($store.state.fastPermissoes.administrador == 'S')">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin/meus-cursos'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/meus-cursos')"
              >Meus cursos</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin/curso/' + $route.params.id_curso"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/curso/' + $route.params.id_curso)"
              >Curso 
                <span v-if="fast_curso.nome_curso">{{ fast_curso.nome_curso }}</span>
                <span v-else>{{ fast_curso.nome_curso_mae }}</span>
              </a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Aulas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Aulas do curso
                  <span v-if="fast_curso.nome_curso">{{ fast_curso.nome_curso }}</span>
                  <span v-else>{{ fast_curso.nome_curso_mae }}</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-2 pl-0">
                  <h4 class="aluno_font_color">
                    Total de aula ({{ aulasTotal.length }})
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4 pl-0">
                  <input
                    v-model="fastAulaFiltroNome"
                    type="text"
                    class="form-control"
                    placeholder="Filtrar por nome"
                    @keyup="filtraAula"
                  >
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-4 pr-0 text-right">
                  <a
                    v-if="$store.state.fastPermissoes.administrador == 'S'"
                    class="btn btn-primary ml-2 mr-2"
                    :href="'/plataforma/' + $route.params.id_plataforma + '/admin/curso/' + $route.params.id_curso"
                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/curso/' + $route.params.id_curso)"
                  >Gerenciar curso</a>
                  <a
                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_adicionam_aula"
                    class="btn btn-primary btn-lg ml-2 mr-2"
                    href="#"
                    @click.prevent="showModal('modalCriarAula')"
                  >+ Criar Aula</a>
                </div>
              </div>

              <div
                v-if="aulasFiltro.length"
                class="row"
              >
                <div
                  v-for="aula in pageOfAulas"
                  :key="aula.id_aula"
                  class="col-12 mb-4 shadow p-3 mb-5 bg-white rounded"
                >
                  <h4>{{ aula.sequencia }} - {{ aula.titulo_aula }}</h4>
                  <tabs :options="{ useUrlFragment: false }">
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Detalhes da aula"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 text-center">
                          <div class="row">
                            <div class="col-12">
                              <h6>Professor</h6>
                              <table class="m-auto">
                                <tr
                                  v-if="aula.id_professor && aula.professor"
                                  class="text-center"
                                >
                                  <td class="p-1">
                                    <div
                                      :style="{
                                        backgroundImage: 'url(' + ajustaLinkImageUser(aula.professor[0].image) + ')',
                                        width: '60px',
                                        height: '60px',
                                        margin: 'auto',
                                        marginBottom: '5px',
                                        borderRadius: '50%',
                                        backgroundSize: '100%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: '50%',
                                      }"
                                    />
                                  </td>
                                  <td class="p-1">
                                    {{ aula.professor[0].first_name + " " + aula.professor[0].last_name }}
                                  </td>
                                </tr>
                                <tr
                                  v-else
                                  class="text-center"
                                >
                                  <td
                                    v-if="!aula.id_professor && aula.professor"
                                    colspan="2"
                                  >
                                    Nenhum professor
                                  </td>
                                  <td
                                    v-if="aula.id_professor && !aula.professor"
                                    colspan="2"
                                  >
                                    Professor não cadastrado no curso
                                  </td>
                                </tr>
                              </table>

                              <button
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_alteram_aula || aula.id_plataforma_origem == $route.params.id_plataforma"
                                class="btn btn-primary btn-sm mt-2"
                                @click.prevent="exibeBuscaProfessor(aula)"
                              >
                                <small>
                                  <i
                                    class="fa fa-cog"
                                    aria-hidden="true"
                                  />
                                  Configurar
                                </small>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6>Título da aula</h6>
                              <input
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_alteram_aula || aula.id_plataforma_origem == $route.params.id_plataforma"
                                v-model="aula.titulo_aula"
                                type="text"
                                class="form-control"
                                @keyup.prevent="aula.alteracao_pendente = true"
                              >
                              <input
                                v-else
                                type="text"
                                class="form-control"
                                :value="aula.titulo_aula"
                                readonly
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                              <h6>Detalhes da aula</h6>
                              <textarea
                                v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_alteram_aula || aula.id_plataforma_origem == $route.params.id_plataforma"
                                v-model="aula.detalhe_aula"
                                rows="5"
                                class="form-control"
                                @keyup="aula.alteracao_pendente = true"
                              />
                              <textarea
                                v-else
                                v-model="aula.detalhe_aula"
                                rows="5"
                                class="form-control"
                                readonly
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 text-center">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6>Aula publicada</h6>
                              <div class="fd-app-meus-cursos mt-0">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0">
                                    <label
                                      v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_alteram_aula || aula.id_plataforma_origem == $route.params.id_plataforma"
                                      class="switch"
                                    >
                                      <input
                                        v-if="aula.publicada"
                                        type="checkbox"
                                        checked="checked"
                                        @click="alteraAulaPublicada(aula)"
                                      >
                                      <input
                                        v-else
                                        type="checkbox"
                                        @click="alteraAulaPublicada(aula)"
                                      >

                                      <span class="slider round" />
                                    </label>
                                    <label v-else>
                                      <strong
                                        v-if="aula.publicada"
                                        class="text-success"
                                      >Sim</strong>
                                      <strong
                                        v-else
                                        class="text-danger"
                                      >Não</strong>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-cogs' aria-hidden='true'></i>&nbsp"
                      name="Conteúdo programático"
                    >
                      <h4>Conteúdo programático</h4>
                      <div class="row mt-4">
                        <div
                          class="col-sm-12 col-md-12 col-lg-12"
                        >
                          <quill-editor
                            v-model="aula.conteudo_programatico"
                            :options="editorOption"
                            @change="aula.alteracao_pendente = true"
                          />
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-file-pdf' aria-hidden='true'></i>&nbsp"
                      name="Atividades"
                      :suffix="'<span class=&quot;suffix&quot;>' + (aula.atividades ? aula.atividades.length : '0') + '</span>'"
                    >
                      <!-- Permissão de alteração -->
                      <div
                        v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_alteram_aula || aula.id_plataforma_origem == $route.params.id_plataforma"
                        class="row"
                      >
                        <div class="col-sm-12 col-md-12 col-lg-6 text-left">
                          <h4>Atividades</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                            @click.prevent="exibeModalCriarAtividade(aula.id_aula)"
                          >
                            <small>+ &nbsp; Adicionar atividade</small>
                          </button>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <table
                            v-if="aula.atividades"
                            class="table table-striped table-bordered"
                          >
                            <thead>
                              <tr class="bg-dark text-white">
                                <th
                                  style="width: 50px"
                                  scope="col"
                                >
                                  Sequência
                                </th>
                                <th scope="col">
                                  Título da atividade
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Tipo
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Conteúdo
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Publicada
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Opções
                                </th>
                              </tr>
                            </thead>

                            <draggable
                              v-if="aula.atividades.length"
                              tag="tbody"
                              :list="aula.atividades"
                              @end="alteraSequenciaAtividade(aula.id_aula, aula.atividades)"
                            >
                              <tr
                                v-for="atividade in aula.atividades"
                                :key="atividade.id_atividade"
                                style="cursor: move"
                              >
                                <td class="text-center">
                                  {{ atividade.sequencia }}
                                </td>
                                <td>{{ atividade.titulo_atividade }}</td>
                                <td class="text-center">
                                  {{ formataTipoAtividade(atividade.tipo_atividade) }}
                                </td>
                                <td class="text-center">
                                  <div v-if="atividade.tipo_atividade == 'RF'">
                                    <a
                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                      :href="corrigeLinkConcurseiro(atividade.referencia_atividade)"
                                      target="_blank"
                                      download
                                    >
                                      <small>
                                        <i
                                          class="fa fa-eye"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Visualizar
                                      </small>
                                    </a>
                                  </div>
                                  <div v-if="atividade.tipo_atividade == 'VA' || atividade.tipo_atividade == 'AD'">
                                    <button
                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                      @click="visualizarAtividade(atividade)"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-play"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Visualizar
                                      </small>
                                    </button>
                                  </div>
                                  <div v-if="atividade.tipo_atividade == 'PS' || atividade.tipo_atividade == 'QZ' || atividade.tipo_atividade == 'AV' || atividade.tipo_atividade == 'SM' || atividade.tipo_atividade == 'LV'" />
                                </td>
                                <td>
                                  <div class="fd-app-meus-cursos mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                        <label class="switch">
                                          <input
                                            v-if="atividade.publicada"
                                            type="checkbox"
                                            checked="checked"
                                            @click="alteraAtividadePublicada(atividade)"
                                          >
                                          <input
                                            v-else
                                            type="checkbox"
                                            @click="alteraAtividadePublicada(atividade)"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td class="text-center">
                                  <a
                                    class="btn btn-primary btn-sm mt-2 ml-2"
                                    :href="'/plataforma/' + $route.params.id_plataforma + '/admin/curso/' + $route.params.id_curso + '/aulas/' + atividade.id_aula + '/atividade/' + atividade.id_atividade"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/curso/' + $route.params.id_curso + '/aulas/' + atividade.id_aula + '/atividade/' + atividade.id_atividade)"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-cog"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Configurar
                                    </small>
                                  </a>
                                  <button
                                    class="btn btn-danger btn-sm mt-2 ml-2"
                                    @click="exibeModalExcluirAtividade(atividade)"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Excluir
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            </draggable>

                            <tbody v-else>
                              <tr>
                                <td colspan="6">
                                  Nenhum atividade cadastrada
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!-- Sem permissão de alteração -->
                      <div
                        v-else
                        class="row"
                      >
                        <div class="col-sm-12 col-md-12 col-lg-6 text-left">
                          <h4>Atividades</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right" />
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <table
                            v-if="aula.atividades"
                            class="table table-striped table-bordered"
                          >
                            <thead>
                              <tr class="bg-dark text-white">
                                <th
                                  style="width: 50px"
                                  scope="col"
                                >
                                  Sequência
                                </th>
                                <th scope="col">
                                  Título da atividade
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Tipo
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Conteúdo
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Publicada
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Opções
                                </th>
                              </tr>
                            </thead>

                            <tbody v-if="aula.atividades.length">
                              <tr
                                v-for="atividade in aula.atividades"
                                :key="atividade.id_atividade"
                              >
                                <td class="text-center">
                                  {{ atividade.sequencia }}
                                </td>
                                <td>{{ atividade.titulo_atividade }}</td>
                                <td class="text-center">
                                  {{ formataTipoAtividade(atividade.tipo_atividade) }}
                                </td>
                                <td class="text-center">
                                  <div v-if="atividade.tipo_atividade == 'RF'">
                                    <a
                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                      :href="corrigeLinkConcurseiro(atividade.referencia_atividade)"
                                      target="_blank"
                                      download
                                    >
                                      <small>
                                        <i
                                          class="fa fa-eye"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Visualizar
                                      </small>
                                    </a>
                                  </div>
                                  <div v-if="atividade.tipo_atividade == 'VA' || atividade.tipo_atividade == 'AD'">
                                    <button
                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                      @click="visualizarAtividade(atividade)"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-play"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Visualizar
                                      </small>
                                    </button>
                                  </div>
                                  <div v-if="atividade.tipo_atividade == 'PS' || atividade.tipo_atividade == 'QZ' || atividade.tipo_atividade == 'AV' || atividade.tipo_atividade == 'SM' || atividade.tipo_atividade == 'LV'" />
                                </td>
                                <td>
                                  <div class="fd-app-meus-cursos mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                        <strong
                                          v-if="atividade.publicada"
                                          class="text-success"
                                        >Sim</strong>
                                        <strong
                                          v-else
                                          class="text-danger"
                                        >Não</strong>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td class="text-center">
                                  <a
                                    class="btn btn-primary btn-sm mt-2 ml-2"
                                    :href="'/plataforma/' + $route.params.id_plataforma + '/admin/curso/' + $route.params.id_curso + '/aulas/' + atividade.id_aula + '/atividade/' + atividade.id_atividade"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-cog"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Detalhes
                                    </small>
                                  </a>
                                </td>
                              </tr>
                            </tbody>

                            <tbody v-else>
                              <tr>
                                <td colspan="6">
                                  Nenhum atividade cadastrada
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </tab>

                    <tab
                      prefix="<i class='fas fa-book' aria-hidden='true'></i>&nbsp"
                      name="Disciplinas"
                      :suffix="'<span class=&quot;suffix&quot;>' + (aula.disciplinas ? aula.disciplinas.length : '0') + '</span>'"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 text-left">
                          <h4>Disciplinas</h4>
                        </div>
                        <div
                          v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_alteram_aula || aula.id_plataforma_origem == $route.params.id_plataforma"
                          class="col-sm-12 col-md-12 col-lg-6 text-right"
                        >
                          <button
                            v-if="fastDisciplinasCarregadas"
                            class="btn btn-sm btn-primary mt-2"
                            @click="exibeModalEscolherDisciplina(aula)"
                          >
                            <small>+ Adicionar disciplinas</small>
                          </button>
                          <button
                            v-else
                            class="btn btn-sm btn-primary mt-2"
                          >
                            <small>Aguarde o carregamento...</small>
                          </button>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                          <table
                            v-if="aula.disciplinas"
                            class="table table-striped table-bordered"
                          >
                            <thead>
                              <tr class="bg-dark text-white">
                                <th scope="col">
                                  Nome da disciplina
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Opções
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="aula.disciplinas.length">
                              <tr
                                v-for="disciplina in aula.disciplinas"
                                :key="disciplina.id_plataforma_area_disciplina"
                              >
                                <td>{{ disciplina.descricao }}</td>
                                <td class="text-center">
                                  <button
                                    v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || $route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_alteram_aula || aula.id_plataforma_origem == $route.params.id_plataforma"
                                    class="btn btn-danger btn-sm mt-2 ml-2"
                                    @click="excluirDisciplinaAula(disciplina)"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Excluir
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  <h6>Nenhuma disciplina encontrada</h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </tab>

                    <tab
                      prefix="<i class='fa fa-cogs' aria-hidden='true'></i>&nbsp"
                      name="Estatísticas"
                      :is-disabled="false"
                    >
                      <h4>Estatísticas</h4>
                      <div class="row mt-4">
                        <div
                          v-if="aula.estatisticasCarregou"
                          class="col-sm-12 col-md-12 col-lg-12 table-responsive"
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr class="bg-dark text-white">
                                <th
                                  style="width: 50px"
                                  class="text-center"
                                  scope="col"
                                >
                                  Id
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Nome
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Perfil
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Status
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Realizou
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Data de início
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Data de finalização
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="aula.pageOfEstatisticas.length">
                              <tr
                                v-for="u in aula.pageOfEstatisticas"
                                :key="u.id_usuario"
                              >
                                <td>#{{ u.id_usuario }}</td>
                                <td>
                                  {{ u.nome_usuario }}
                                </td>
                                <td>
                                  <span v-if="u.administrador == 'S'">
                                    Administrador
                                  </span>
                                  <span v-else-if="u.professor == 'S'">
                                    Professor
                                  </span>
                                  <span v-else> Aluno </span>
                                </td>
                                <td class="text-center">
                                  <span
                                    v-if="u.status == 'E'"
                                    class="text-success"
                                  >
                                    Em execução
                                  </span>
                                  <span
                                    v-else-if="u.status == 'F'"
                                    class="text-secondary"
                                  >
                                    Finalizada
                                  </span>
                                  <span v-else>
                                    {{ u.status }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span v-if="u.count">{{ u.count }} vezes</span>
                                </td>
                                <td class="text-center">
                                  <span v-if="u.data_inicio">
                                    {{ new Date(u.data_inicio).toLocaleDateString() }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span v-if="u.data_fim">
                                    {{ new Date(u.data_fim).toLocaleDateString() }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="7"
                                  class="text-center"
                                >
                                  <h6>Nenhum aluno iniciou essa aula</h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="col-12 text-center mt-4">
                            <Pagination
                              :page-size="10"
                              :items="aula.estatisticasFiltro"
                              @changePage="aula.pageOfEstatisticas = $event"
                            />
                          </div>
                        </div>
                        <div
                          v-else
                          class="col-sm-12 col-md-12 col-lg-12 text-center"
                        >
                          <button
                            class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                            @click.prevent="getFastAulaEstatisticas(aula)"
                          >
                            <small>Exibir estatísticas</small>
                          </button>
                        </div>
                      </div>
                    </tab>

                    <tab
                      v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem || fast_curso.filhas_alteram_aula || aula.id_plataforma_origem == $route.params.id_plataforma"
                      prefix="<i class='fa fa-cogs' aria-hidden='true'></i>&nbsp"
                      name="Opções"
                    >
                      <h4>Opções</h4>
                      <div class="row mt-4">
                        <div
                          v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem"
                          class="col-sm-12 col-md-12 col-lg-2 text-center"
                        >
                          <h6>Alterar sequência</h6>
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                              <input
                                v-model="aula.sequencia"
                                class="form-control text-center m-auto"
                                type="number"
                                min="1"
                                @change.prevent="aula.alteracao_pendente = true"
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="$route.params.id_plataforma == fast_curso.id_plataforma_origem"
                          class="col-sm-12 col-md-12 col-lg-2 text-center"
                        >
                          <h6>Copiar aula</h6>
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                              <button
                                class="btn btn-primary btn-block ml-2"
                                @click.prevent="exibeModalCopiarAula(aula)"
                              >
                                <small> <b-icon-search /> &nbsp; Buscar curso </small>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-2 text-center">
                          <h6>Excluir aula</h6>
                          <button
                            class="btn btn-danger btn-block ml-2"
                            @click="exibeModalExcluirAula(aula)"
                          >
                            <small> <i
                              class="fa fa-ban"
                              aria-hidden="true"
                            /> &nbsp; Excluir </small>
                          </button>
                        </div>
                      </div>
                    </tab>
                  </tabs>
                  <div
                    v-if="aula.alteracao_pendente"
                    class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                  >
                    <button
                      class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                      @click.prevent="atualizaAula(aula)"
                    >
                      <small>
                        <i
                          class="fa fa-floppy-o"
                          aria-hidden="true"
                        />
                        &nbsp;Salvar alterações
                      </small>
                    </button>
                    <button
                      class="btn btn-secondary btn-sm mt-2 ml-2"
                      @click.prevent="getAulasAtividades()"
                    >
                      <small>
                        <i
                          class="fa fa-ban"
                          aria-hidden="true"
                        />
                        &nbsp;Cancelar
                      </small>
                    </button>
                  </div>
                </div>
                <div class="col-12 text-center mt-4">
                  <Pagination
                    :page-size="10"
                    :items="aulasFiltro"
                    @changePage="pageOfAulas = $event"
                  />
                </div>
              </div>
              <div
                v-else
                class="row"
              >
                <div class="col-12 mb-4 text-center">
                  <h4>Nenhuma aula encontrada</h4>
                </div>
              </div>

              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin/meus-cursos'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/meus-cursos')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalBuscaProfessores"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Buscar professor</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalBuscaProfessores')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraProfessor"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      Nome
                    </th>
                    <th scope="col">
                      Email
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfProfessores.length">
                  <tr
                    v-for="prof in pageOfProfessores"
                    :key="prof.id_professor"
                  >
                    <td scope="row">
                      <div
                        :style="{
                          backgroundImage: 'url(' + ajustaLinkImageUser(prof.image) + ')',
                          width: '60px',
                          height: '60px',
                          margin: 'auto',
                          marginBottom: '5px',
                          borderRadius: '50%',
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50%',
                        }"
                      />
                    </td>
                    <td class="align-middle">
                      {{ prof.first_name + " " + prof.last_name }}
                    </td>
                    <td class="align-middle">
                      {{ prof.email }}
                    </td>
                    <td class="align-middle text-center">
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar como professor desta aula"
                        @click="alteraProfessoraAula(prof)"
                      >
                        <small> <b-icon-person-plus-fill /> Professor </small>
                      </button>
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar como professor desta aula"
                        @click="alteraProfessores(prof)"
                      >
                        <small> <b-icon-person-plus-fill /> Para todas as aulas </small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhum professor cadastrado no curso
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="professoresFiltro"
                @changePage="pageOfProfessores = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCriarAula"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Criar aula</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input
                  v-model="novaAula.titulo_aula"
                  class="form-control"
                  type="text"
                  placeholder="Titulo da aula"
                >
                <textarea
                  v-model="novaAula.detalhe_aula"
                  class="form-control mt-2"
                  placeholder="Descrição do Aula"
                />
              </div>
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="validaCriarAula()"
                >
                  Criar Aula
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarAtividade"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Criar atividade</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('RF')"
                  >
                    <div class="text-center">
                      <b-icon-file-earmark-text font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Arquivo</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('AD')"
                  >
                    <div class="text-center">
                      <b-icon-volume-up font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Audio</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('VA')"
                  >
                    <div class="text-center">
                      <b-icon-collection-play-fill font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Video</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('QZ')"
                  >
                    <div class="text-center">
                      <b-icon-ui-checks font-scale="3" /> 
                      <br>
                      <div class="text-center">
                        <span>Quiz</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('PS')"
                  >
                    <div class="text-center">
                      <b-icon-clipboard-data font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Pesquisa</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('AV')"
                  >
                    <div class="text-center">
                      <b-icon-stopwatch font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Avaliação objetiva</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('UP')"
                  >
                    <div class="text-center">
                      <b-icon-cloud-upload font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Upload</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div
                    style="cursor: pointer"
                    href="#"
                    @click.prevent="exibeModalCriarAtividadeTipo('LV')"
                  >
                    <div class="text-center">
                      <b-icon-camera-video font-scale="3" />
                      <br>
                      <div class="text-center">
                        <span>Live</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCriarAtividadeTipo"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>
            Atividade do tipo
            {{ formataTipoAtividade(novaAtividade.tipo_atividade) }}
          </h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAtividadeTipo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input
                  v-model="novaAtividade.titulo_atividade"
                  class="form-control"
                  type="text"
                  placeholder="Titulo da atividade"
                >
                <textarea
                  v-model="novaAtividade.detalhe_atividade"
                  class="form-control mt-2"
                  placeholder="Descrição do atividade"
                />
              </div>
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="validaCriarAtividade()"
                >
                  Criar atividade
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirAtividade"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir atividade?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirAtividade()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirAula"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir aula?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirAula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirAula()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalVisualizarAtividade"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Atividade - {{ atividadeVisualizar.titulo_atividade }}</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalVisualizarAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div
              v-if="atividadeVisualizar.tipo_atividade == 'VA'"
              class="col-12"
            >
              <iframe
                :src="atividadeVisualizar.referencia_atividade"
                width="100%"
                height="315"
                frameborder="0"
              />
            </div>
            <div
              v-else
              class="col-12"
            >
              <audio
                controls
                class="d-block m-auto"
              >
                <source
                  :src="atividadeVisualizar.referencia_atividade"
                  type="audio/mpeg"
                >
                Seu navegador não suporte o player de audio.
              </audio>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEscolherDisciplina"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Escolha uma disciplina</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherDisciplina')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div>
                <vue-tree-list
                  :model="treeDisciplinas"
                  default-tree-node-name="Disciplina"
                  :default-expanded="false"
                  @delete-node="confirmaDisciplinaEscolhida"
                >
                  <template v-slot:leafNameDisplay="slotProps">
                    <span>{{ slotProps.model.name }}</span>
                  </template>
                  <span
                    slot="delNodeIcon"
                    title="Escolher essa disciplina"
                    style="cursor: pointer"
                    class="icon"
                  >
                    <button class="btn btn-sm btn-success pt-0 pb-0">
                      <small>+ Adicionar</small>
                    </button>
                  </span>
                  <span
                    slot="addTreeNodeIcon"
                    title="Escolher essa disciplina"
                    class="icon d-none"
                  />
                  <span
                    slot="addLeafNodeIcon"
                    class="icon d-none"
                  />
                  <span
                    slot="editNodeIcon"
                    title="Editar"
                    class="icon d-none"
                  />
                  <span
                    slot="treeNodeIcon"
                    class="icon"
                  >📚</span>
                </vue-tree-list>
                <div
                  v-if="!treeDisciplinas.children"
                  class="text-center"
                >
                  <h6>Nenhuma disciplina cadastrada</h6>
                  <a
                    :href="'/plataforma/' + $route.params.id_plataforma + '/admin/personalizar-plataforma#disciplinas'"
                    class="btn btn-sm btn-primary"
                  >Cadastrar disciplinas</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCopiarAula"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Escolha o curso de destino</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCopiarAula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Filtrar por nome"
                    @keyup="filtraCursos"
                  >
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                  <table class="table table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" />
                        <th scope="col">
                          Curso
                        </th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          Ação
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="pageOfMeusCursos.length">
                      <tr
                        v-for="(curso, index) in pageOfMeusCursos"
                        :key="curso.id_curso"
                      >
                        <td class="align-middle">
                          {{ index + 1 }}
                        </td>
                        <td class="align-middle">
                          <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                          <span v-else>{{ curso.nome_curso_mae }}</span>
                        </td>
                        <td class="align-middle text-center">
                          <span
                            class="btn btn-sm btn-success text-white pt-0 pb-0"
                            @click="copiarAula(fastExcluirAula, curso)"
                          >
                            <small>+ Copiar para esse curso</small>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          class="text-center"
                          colspan="3"
                        >
                          Nenhuma curso encontrado
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 text-center mt-4">
                  <Pagination
                    :page-size="10"
                    :items="fastMeusCursosFiltro"
                    @changePage="pageOfMeusCursos = $event"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfirmarDespublicarAtividade"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Tem certeza que deseja despublicar a atividade?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfirmarDespublicarAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mb-4">
              <p class="text-danger">
                O sistema identificou que alguns alunos já iniciaram essa atividade. Isso fará com que os dados de quem já realizou essa atividade seja apagado.
              </p>
            </div>
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                >
                  Confirmar
                </button>
                <button
                  href="#"
                  type="button"
                  class="btn btn-secondary ml-2"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import draggable from "vuedraggable"; //https://github.com/SortableJS/Vue.Draggable
import { VueTreeList, Tree, TreeNode } from "vue-tree-list"; //https://github.com/ParadeTo/vue-tree-list#readme
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "HomeInternoAdminCursoAulas",
  components: {
    Pagination,
    draggable,
    VueTreeList,
    quillEditor
  },
  mixins: [methods],
  data: function() {
    return {
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fast_curso: {},
      // Aulas
      aulasTotal: [],
      aulasFiltro: [],
      pageOfAulas: [],
      // Lista de professores
      professoresTotal: [],
      professoresFiltro: [],
      pageOfProfessores: [],
      // Nova atividade
      novaAtividade: {
        id_curso: 0,
        id_aula: 0,
        incrementa_evolucao: true,
        publicada: false,
        titulo_atividade: "",
        detalhe_atividade: "...",
        tipo_atividade: "",
        referencia_atividade: "",
      },
      // Excluir atividade
      fastExcluirAtividade: [],
      // Nova aula
      novaAula: {
        id_aula: 0,
        sequencia: 0,
        evoulucao_aula: true,
        publicada: false,
        detalhe_aula: "",
        titulo_aula: "",
        id_curso: 0,
        id_professor: 0,
        id_plataforma_origem: 0,
        conteudo_programatico: ""
      },
      // Aula alterada
      aulaAlterada: [],
      // Visualização de atividade
      atividadeVisualizar: [],
      // Disciplinas
      treeDisciplinas: new Tree({
        name: "Primeira disciplina",
        id: 0,
        isLeaf: false,
        addLeafNodeDisabled: true,
        children: [],
      }),
      fastDisciplinasCarregadas: false,
      modalWidth: "80%",
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastAulaFiltroNome: "",
      // Cursos
      fastMeusCursos: [],
      pageOfMeusCursos: [],
      fastMeusCursosFiltro: [],
      // Estatísticas
      fastAulaEstatisticaCarregou: false,
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ],
        },
      },
    };
  },
  mounted: function() {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getAulasAtividades();
          this.getFastCurso();
          /*
          if (this.$store.state.fastPermissoes.ativo == "S" && this.$store.state.fastPermissoes.administrador == "S") {
            this.getAulasAtividades();
            this.getFastCurso();
          } else if (this.$store.state.fastPermissoes.professor_only) {
            this.getAulasAtividades();
            this.getFastCursoProfessor();
            this.exibeToasty("Você está acessando com perfil de professor", "info");
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Você não possui permissões", "error");
          }*/
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastCurso() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_plataforma/lista_curso_admin?id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        console.log("getFastCurso", json);
        let objCurso = Array.from(json);
        if (objCurso.length > 0) {
          this.fast_curso = objCurso[0];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastCursoProfessor() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_plataforma/lista_curso_professor?id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length > 0) {
          this.fast_curso = objCurso[0];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraAula(e) {
      let text = e.target.value;
      this.aulasFiltro = this.aulasTotal.filter((e) => e.titulo_aula.toLowerCase().indexOf(text.toLowerCase()) != "-1");
    },
    async getAulasAtividades() {
      this.fastAulaFiltroNome = "";
      // Loading
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula/lista?id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let objAulas = Array.from(json);
        if (objAulas.length > 0) {
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade?id_atividade=&id_curso=" + this.$route.params.id_curso + "&id_aula=", this.fastAjaxOptions("GET"));
            let json = await resp.json();
            let objAtividades = Array.from(json);
            
            console.log("ObjAtividades", objAtividades)
            if (objAtividades.length > 0) {
              // Insere as atividades dentro de cada aula
              objAulas.forEach((aula, index) => {
                // Adiciona atributo de verificação de alteração
                objAulas[index].alteracao_pendente = false;
                // Adiciona atributo das disciplinas que será carregada depois
                objAulas[index].disciplinas = [];
                objAulas[index].estatisticasCarregou = false;
                objAulas[index].estatisticasTotal = [];
                objAulas[index].estatisticasFiltro = [];
                objAulas[index].pageOfEstatisticas = [];
                objAtividades.forEach((atividade, index2) => {
                  // Adiciona atributo de verificação de alteração
                  if (!objAtividades[index2].alteracao_pendente) objAtividades[index2].alteracao_pendente = false;

                  if (aula.id_aula == atividade.id_aula) {
                    if (!objAulas[index].atividades) {
                      objAulas[index].atividades = [];
                    }
                    objAulas[index].atividades.push(atividade);
                  }
                });
              });
            } else {
              objAulas.forEach((d) => {
                d.disciplinas = [];
                d.estatisticasCarregou = false;
                d.estatisticasTotal = [];
                d.estatisticasFiltro = [];
                d.pageOfEstatisticas = [];
              });
            }
            this.aulasTotal = objAulas;
            this.aulasFiltro = objAulas;

            if (this.getUrlParameter("aula")) {
              this.aulasFiltro.forEach((a) => {
                if (a.id_aula == this.getUrlParameter("aula")) {
                  let e = { target: { value: a.titulo_aula } };
                  this.filtraAula(e);
                  this.fastAulaFiltroNome = e.target.value;
                }
              });
            }
            // Disciplinas da aula
            this.aulasFiltro.forEach((e) => this.getFastDisciplinasAula(e.id_aula));
            // Busca professores
            this.getProfessoresCurso();
            // Busca disciplinas
            this.getFastDisciplinas();
            // Informa ao component pai para interromper o loading
            this.$store.state.fastCarregando = false;
          } catch (e) {
            console.log("Erro", e);
          }
        } else {
          this.aulasTotal = [];
          this.aulasFiltro = [];
          // Informa ao component pai para interromper o loading
          this.$store.state.fastCarregando = false;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    formataTipoAtividade(e) {
      switch (e) {
        case "RF":
          return "Arquivo";
          break;
        case "AD":
          return "Audio";
          break;
        case "VA":
          return "Video Aula";
          break;
        case "PS":
          return "Pesquisa";
          break;
        case "AV":
          return "Avaliação";
          break;
        case "SM":
          return "Simulado";
          break;
        case "QZ":
          return "Quiz";
          break;
        case "LV":
          return "Live";
          break;
        case "UP":
          return "Upload";
          break;
        default:
          return "Desconhecido";
      }
    },
    exibeBuscaProfessor(aula) {
      this.aulaAlterada = aula;
      this.showModal("modalBuscaProfessores");
    },
    alteraProfessoraAula(professor) {
      this.aulaAlterada.id_professor = professor.id_professor;
      this.aulasFiltro.forEach((e) => {
        if (e.id_aula == this.aulaAlterada.id_aula) {
          e.professor = [];
          e.professor.push(professor);
        }
      });
      this.atualizaAula(this.aulaAlterada);
      this.hideModal("modalBuscaProfessores");
    },
    async alteraProfessores(professor) {
      this.$store.state.fastCarregando = true;
      const model = {
        fast_plataforma: {
          id_plataforma: this.$route.params.id_plataforma,
        },
        fast_curso_aula: {
          id_professor: professor.id_professor,
          id_curso: this.$route.params.id_curso,
        },
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula/atualiza_professores", this.fastAjaxOptions("POST", JSON.stringify(model)));
        let json = await resp.json();
        console.log(json);
        let obj = Array.from(json);
        if (obj.length > 0) {
          
          this.exibeToasty("Professor atualizado com sucesso", "success")
          this.getAulasAtividades();
        } else {
          this.$store.state.fastCarregando = false;
          
          this.exibeToasty("Erro ao atualizar professor", "error")
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getProfessoresCurso() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_professor/lista?id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=" + this.$route.params.id_curso, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let objProfs = Array.from(json);
        if (objProfs.length > 0) {
          this.professoresTotal = objProfs;
          this.professoresFiltro = objProfs;

          if (this.aulasTotal.length > 0) {
            this.aulasTotal.forEach((aula, index) => {
              objProfs.forEach((prof) => {
                if (aula.id_professor == prof.id_professor) {
                  if (!this.aulasTotal[index].professor) {
                    this.aulasTotal[index].professor = [];
                  }
                  this.aulasTotal[index].professor.push(prof);
                }
              });
            });
          }
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraProfessor(e) {
      let text = e.target.value;
      this.professoresFiltro = this.professoresTotal.filter((e) => e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1");
    },
    alteraSequenciaAtividade(id_aula, atividades) {
      atividades.forEach((e, index) => {
        e.sequencia = index + 1;
        setTimeout(() => {
          this.atualizaAtividadeAntigo(e);
        }, 2000);
      });
      //let aulaAlterada = this.pageOfAulas.filter((aula) => aula.id_aula == id_aula);
      //aulaAlterada[0].alteracao_pendente = true;
    },
    async atualizaAula(aula) {
      this.$store.state.fastCarregando = true;
      this.promiseAtualizarFastApi(aula, "fast_curso_aula").then((res) => {
        this.$store.state.fastCarregando = false;
        if (res.length) {
          this.exibeToasty("Aula atualizada com sucesso", "success");
          let aulaAlterada = this.pageOfAulas.filter((a) => a.id_aula == aula.id_aula);          
          aulaAlterada[0].alteracao_pendente = false;          
        } else {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao atualizar", "error");
        }
      })

    },
    async verificaTipoAtividade(atividade) {
      return new Promise(async (resolve, reject) => {
        switch (atividade.tipo_atividade) {
          case "PS":
            this.promiseGetFastApi("api/fast_curso_aula_atividade_pesquisa/lista", "id_atividade=" + atividade.id_atividade).then(obj => {
              console.log("passou", obj)
              if (obj.length) {
                resolve(true)
              }else{
                reject("A pesquisa está sem descrição")
              }
            }).catch(e => {
              reject(e)
            })
            break;
        

          case "QZ":
            this.promiseGetFastApi("api/fast_curso_aula_atividade_quiz/lista", "id_atividade=" + atividade.id_atividade).then(obj => {
              if (obj.length > 0) {
                resolve(true)
              }else{
                reject("O quiz está sem perguntas.")
              }
            }).catch(e => {
              reject(e)
            })
          break;
    

          case "AV":
            this.promiseGetFastApi("api/fast_curso_aula_atividade_prova/lista_novo", "id_atividade=" + atividade.id_atividade).then(obj => {
              if (obj.length) {
                resolve(true)
              } else {
                reject("A atividade está sem opções")
              }
            }).catch(e => {
              reject(e)
            })
          break;
        
          default:
            if(atividade.referencia_atividade == "" && (atividade.tipo_atividade == "VA" || atividade.tipo_atividade == "RF" || atividade.tipo_atividade == "AD")){
              reject("A atividade está sem arquivo")
            }else{
              resolve(true)
            }
          break;
        }
      });
    },
    async atualizaAtividade(atividade) {
      this.verificaTipoAtividade(atividade)
      .then(() => {
        if (!atividade.incrementa_evolucao) atividade.incrementa_evolucao = true;
      this.promisePostFastApi(atividade, "api/fast_curso_aula_atividade/atualiza_novo").then(obj => {
      if (obj.length > 0) {
        this.exibeToasty("Atividade atualizada com sucesso", "success");
      } else {
        this.exibeToasty("Erro ao atualizar atividade", "error");
      }
      }).catch(e => {
        console.log("Erro", e);
      })
      })
      .catch(e => {
        this.exibeToasty(e, "error")
      })
      
    },
    async atualizaAtividadeAntigo(atividade) {
      if (!atividade.incrementa_evolucao) atividade.incrementa_evolucao = true;
      this.promisePostFastApi(atividade, "api/fast_curso_aula_atividade/atualiza_novo").then(obj => {
        if (obj.length > 0) {
          this.exibeToasty("Atividade atualizada com sucesso", "success");
        } else {
          this.exibeToasty("Erro ao atualizar atividade", "error");

        }
      }).catch(e => {
        console.log("Erro", e);
      })
    },
    exibeModalCriarAtividadeTipo(tipo_atividade) {
      this.novaAtividade.tipo_atividade = tipo_atividade;
      this.showModal("modalCriarAtividadeTipo", "modalCriarAtividade");
    },
    exibeModalCriarAtividade(id_aula) {
      this.novaAtividade.id_aula = id_aula;
      this.novaAtividade.id_curso = this.$route.params.id_curso;
      this.showModal("modalCriarAtividade");
    },
    validaCriarAtividade() {
      let erros = [];
      if (!this.novaAtividade.titulo_atividade.length) erros.push("O título da atividade não pode ficar em branco");
      if (!this.novaAtividade.tipo_atividade.length) erros.push("O tipo da atividade não foi identificado");
      if (!this.novaAtividade.id_aula) erros.push("Aula não identificado");

      if (erros.length) {
        erros.forEach((e) => {
          
          this.exibeToasty(e, "error")
        });
      } else {
        this.criarAtividade();
      }
    },
    async criarAtividade() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/insere", this.fastAjaxOptions("POST", JSON.stringify(this.novaAtividade)));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/admin/curso/" + this.$route.params.id_curso + "/aulas/" + obj[0].id_aula + "/atividade/" + obj[0].id_atividade;
        } else {
          
          this.exibeToasty("Erro ao criar atividade", "error")
          
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalExcluirAtividade(atividade) {
      this.fastExcluirAtividade = atividade;
      this.showModal("modalExcluirAtividade");
    },
    async excluirAtividade() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula_atividade/exclui", this.fastAjaxOptions("POST", JSON.stringify(this.fastExcluirAtividade)));
        let json = await resp.json();
        let obj = Array.from(json);

        this.fastExcluirAtividade = [];
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Atividade excluída com sucesso",
        });
        this.hideModal("modalExcluirAtividade");
        this.getAulasAtividades();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalExcluirAula(aula) {
      this.fastExcluirAula = aula;
      this.showModal("modalExcluirAula");
    },
    async excluirAula() {
      const fast_curso_aula = {
        id_aula: this.fastExcluirAula.id_aula,
        id_curso: this.fastExcluirAula.id_curso,
        id_plataforma_origem: this.fastExcluirAula.id_plataforma_origem ? this.fastExcluirAula.id_plataforma_origem : this.$route.params.id_plataforma
      }
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula/exclui", this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula)));
        let json = await resp.json();
        console.log("json", json)
        //let obj = Array.from(json);
        window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/admin/curso/" + this.$route.params.id_curso + "/aulas";
      } catch (e) {
        console.log("Erro", e);
      }
      
    },
    exibeModalCopiarAula(aula) {
      this.fastExcluirAula = aula;
      this.$store.state.fastCarregando = true;
      this.getFastCursos()
        .then(() => {
          this.$store.state.fastCarregando = false;
          this.showModal("modalCopiarAula");
        })
        .catch(() => {
          this.$store.state.fastCarregando = false;
          this.$toast.open({
            message: "Erro ao buscar cursos",
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        });
    },
    async copiarAula(aula, curso) {
      this.$store.state.fastCarregando = true;
      const fast_curso_aula = {
        id_curso: curso.id_curso,
        id_aula: aula.id_aula,
      };
      console.log("copiarAula", fast_curso_aula);
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula/copia_aula", this.fastAjaxOptions("POST", JSON.stringify(fast_curso_aula)));
        let json = await resp.json();
        console.log(json);
        let obj = Array.from(json);

        this.hideModal("modalCopiarAula");

        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Aula copiada com sucesso",
        });
      } catch (e) {
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao fazer a cópia da aula",
        });
      }
    },
    filtraCursos(e) {
      let text = e.target.value;
      this.fastMeusCursosFiltro = this.fastMeusCursos.filter((e) => {
        if (e.nome_curso) return e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
        else
          return e.nome_curso_mae.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
      });
},
    validaCriarAula() {
      let erros = [];
      if (!this.novaAula.titulo_aula.length) erros.push("O título da aula não pode ficar em branco");
      this.novaAula.id_curso = parseInt(this.$route.params.id_curso);
      this.novaAula.id_plataforma_origem = parseInt(this.$route.params.id_plataforma);
      if (erros.length) {
        erros.forEach((e) => {
          
          this.exibeToasty(e, "error")
        });
      } else {
        this.criarAula();
      }
    },
    async criarAula() {
      this.$store.state.fastCarregando = true;
      /*
      const fast_curso_aula = {
        id_aula: this.novaAula.id_aula,
        sequencia: this.novaAula.sequencia,
        evoulucao_aula: this.novaAula.evoulucao_aula,
        publicada: this.novaAula.publicada,
        detalhe_aula: this.novaAula.detalhe_aula,
        titulo_aula: this.novaAula.titulo_aula,
        id_curso: this.novaAula.id_curso,
        id_professor: this.novaAula.id_professor,
        id_plataforma_origem: this.novaAula.id_plataforma_origem,
      };

      console.log("fast_curso_aula", fast_curso_aula);
*/
      this.novaAula.id_plataforma_origem = this.$route.params.id_plataforma
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula/insere", this.fastAjaxOptions("POST", JSON.stringify(this.novaAula)));
        let json = await resp.json();
        console.log(json);
        let obj = Array.from(json);

        if (obj.length > 0) {
          window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/admin/curso/" + this.$route.params.id_curso + "/aulas?aula=" + obj[0].id_aula;
        } else {
          this.$store.state.fastCarregando = false;
          
          this.exibeToasty("Erro ao criar aula", "error")
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    alteraAulaPublicada(aula, confirmar = false) {
      aula.publicada = !aula.publicada;
      this.atualizaAula(aula);
      /*
      console.log("alteraAulaPublicada(aula)", aula)
      if (aula.publicada && !confirmar) {
        this.promiseGetFastApi("api/fast_curso_aula/lista_estatisticas", "id_plataforma=" + this.$route.params.id_plataforma + "&id_aula=" + aula.id_aula).then(obj => {
          return new Promise(async (resolve, reject) => {
            if (obj.length) {
              console.log("Existem usuários")
              this.$store.state.fastCarregando = false;
              this.showModal('modalConfirmarDespublicarAula')
              reject(1)
            } else {
              console.log("Nenhum usuário iniciou essa aula")
              aula.publicada = !aula.publicada;
              this.atualizaAula(aula);
            }
          });
        })
        .catch(e => {
          console.log(e);
          this.$store.state.fastCarregando = false;
        })  
      } else {
        aula.publicada = !aula.publicada;
        this.atualizaAula(aula);
      }
      */
    },
    alteraAtividadePublicada(atividade) {
      atividade.publicada = !atividade.publicada;
      this.atualizaAtividade(atividade);
    },
    visualizarAtividade(atividade) {
      if (atividade.tipo_atividade == "VA") {
        if (atividade.referencia_atividade.indexOf("https://vimeo.com/") != "-1") {
          atividade.referencia_atividade = "https://player.vimeo.com/video/" + atividade.referencia_atividade.split("/").pop();
        }
      }
      this.atividadeVisualizar = atividade;
      this.showModal("modalVisualizarAtividade");
    },
    // Disciplina
    async getFastDisciplinas() {
      this.fastDisciplinasCarregadas = false;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_disciplinas/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((e) => {
            e.name = e.descricao;
            e.id = e.id_plataforma_area_disciplina;
            e.isLeaf = false;
            e.addLeafNodeDisabled = true;
          });
          let arrayDisciplinasPai = obj.filter((d) => {
            if (d.id_plataforma_area_disciplina_pai == "0") return d;
          });
          arrayDisciplinasPai.forEach(function(d, index) {
            verificaNivel2(d, index);
          });

          function verificaNivel2(d, index) {
            obj.forEach(function(d2) {
              if (d.id_plataforma_area_disciplina == d2.id_plataforma_area_disciplina_pai) {
                if (!arrayDisciplinasPai[index].children) arrayDisciplinasPai[index].children = [];
                arrayDisciplinasPai[index].children.push(d2);

                verificaNivelUltimos(d2);
              }
            });
          }

          function verificaNivelUltimos(d) {
            obj.forEach(function(d2) {
              if (d.id_plataforma_area_disciplina == d2.id_plataforma_area_disciplina_pai) {
                if (!d.children) d.children = [];
                d.children.push(d2);
                verificaNivelUltimos(d2);
              }
            });
          }

          this.treeDisciplinas = new Tree(arrayDisciplinasPai);
          this.fastDisciplinasCarregadas = true;
        } else {
          this.fastDisciplinasCarregadas = true;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastDisciplinasAula(id_aula) {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_aula_disciplina_plataforma/lista?id_aula=" + id_aula, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          // Insere as disciplinas dentro da aula
          this.aulasFiltro.forEach((aula, index) => {
            if (aula.id_aula == id_aula) {
              this.aulasFiltro[index].disciplinas = [];

              obj.forEach((disciplina, index2) => {
                if (aula.id_aula == disciplina.id_aula) {
                  this.aulasFiltro[index].disciplinas.push(disciplina);
                }
              });
            }
          });

        } else {
          this.aulasFiltro.forEach((aula, index) => {
            if (aula.id_aula == id_aula) {
              this.aulasFiltro[index].disciplinas = [];
              //console.log("Limpando disciplinas", this.aulasFiltro[index]);
            }
          });
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalEscolherDisciplina(aula) {
      this.showModal("modalEscolherDisciplina");
      this.aulaAlterada = aula;
    },
    confirmaDisciplinaEscolhida(obj) {
      let fast_aula_disciplina_plataforma = {
        id_aula: this.aulaAlterada.id_aula,
        id_plataforma_area_disciplina: obj.id,
      };
      fetch(`${settings.endApiFastEad}api/fast_aula_disciplina_plataforma/insere`, this.fastAjaxOptions("POST", JSON.stringify(fast_aula_disciplina_plataforma)))
        .then((resp) => resp.json())
        .then((obj) => {
          this.hideModal("modalEscolherDisciplina");
          // Notificação
          
          this.exibeToasty("Disciplina salva com sucesso", "success")

          this.getFastDisciplinasAula(this.aulaAlterada.id_aula);

          this.aulaAlterada = [];
        })
        .catch((e) => {
          console.log(e);
          // Notificação
          
          this.exibeToasty("Erro ao salvar disciplina", "error")
        });
    },
    async excluirDisciplinaAula(disciplina) {
      let fast_aula_disciplina_plataforma = {
        id_aula: disciplina.id_aula,
        id_plataforma_area_disciplina: disciplina.id_plataforma_area_disciplina,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_aula_disciplina_plataforma/exclui", this.fastAjaxOptions("POST", JSON.stringify(fast_aula_disciplina_plataforma)));
        let json = await resp.json();

        this.getFastDisciplinasAula(disciplina.id_aula);

        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Disciplina excluída com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Cursos
    async getFastCursos() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_plataforma?id_usuario=0" + "&id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=", this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let objPlataforma = Array.from(json);
          if (objPlataforma.length > 0) {
            let obj = objPlataforma.filter((c) => {
              if (c.id_plataforma_origem == this.$route.params.id_plataforma) return c;
            });
            this.fastMeusCursos = obj;
            this.fastMeusCursosFiltro = obj;
          }
          resolve(true);
        } catch (e) {
          reject(true);
        }
      });
    },
    // Estatísticas
    async getFastAulaEstatisticas(aula) {
      this.fastAulaEstatisticaCarregou = false;
      this.$store.state.fastCarregando = true;
      aula.estatisticasCarregou = false;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_aula/lista_estatisticas?id_plataforma=" + this.$route.params.id_plataforma + "&id_aula=" + aula.id_aula, this.fastAjaxOptions("GET"));
        let json = await resp.json();

        let obj = Array.from(json);
        if (obj.length) {
          aula.estatisticasTotal = obj;
          aula.estatisticasFiltro = obj;
        } else {
          aula.estatisticasTotal = [];
          aula.estatisticasFiltro = [];
        }
        this.fastAulaEstatisticaCarregou = true;
        aula.estatisticasCarregou = true;
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Tabs */
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 1em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
  min-height: 100px;
}
</style>
